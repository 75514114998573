import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/": [5],
		"/about": [6],
		"/admin/categorise/[dataImportIdOrAll]": [~7],
		"/admin/deduplicate/[dataImportId=integer]": [~8],
		"/admin/ocr": [~9],
		"/app": [~10,[2]],
		"/app/accounts": [~11,[2]],
		"/app/ai": [~12,[2]],
		"/app/claim/[invitationId=integer]": [~13,[2]],
		"/app/claim/[invitationId=integer]/[claimToken]": [~14,[2],[,3]],
		"/app/common": [~15,[2]],
		"/app/help": [16,[2]],
		"/app/list": [~17,[2]],
		"/app/new": [18,[2]],
		"/app/paid/[productId=integer]": [~19,[2]],
		"/app/partg": [~20,[2]],
		"/app/partn": [~21,[2]],
		"/app/reject/[invitationId=integer]/[claimToken]": [~22,[2]],
		"/app/report": [~23,[2]],
		"/app/settings": [~24,[2]],
		"/app/settings/payments": [~25,[2]],
		"/app/settings/payments/success": [~26,[2]],
		"/app/share": [~27,[2]],
		"/app/statements": [~28,[2]],
		"/app/upgrade": [~29,[2]],
		"/blog/affordable-forensic-accounting-family-law": [~30],
		"/blog/forensic-account-for-self-represented-family-law-litigants": [~31],
		"/categories": [32],
		"/compliance": [33],
		"/faq": [34],
		"/financial-statement-kit": [35],
		"/integration/code": [~36],
		"/leap/redirect": [~37,[4]],
		"/legals": [38],
		"/login": [~39],
		"/login/badlink": [41],
		"/login/[token=uuid]": [~40],
		"/ocr": [42],
		"/privacy": [43],
		"/register": [~44],
		"/support": [45]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';